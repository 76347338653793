
export default function SuspenseContent()
{
    const location = {pathname: window.location.pathname};
    const hereStyle:string = "grow-0 flex-none text-sm font-semibold text-black bg-white px-1 justify-self-end disabled-link";
    const thereStyle:string = "grow-0 flex-none text-sm font-semibold px-1 justify-self-end";

    return(
        <div className='flex px-6 sm:px-32'>
            <div className='flex-1 justify-center'>
                <div className='mx-auto max-w-5xl text-white'> 
                    <div className="w-full h-full text-gray-300  dark:text-gray-200 bg-base-100">
                        <div className='flex flex-row justify-center items-center mt-12 antialiased '>        
                            <div className="flex w-full font-display items-end gap-12">    
                                <div className="grow text-xl font-bold leading-none col-span-2 justify-start">
                                <div className={location.pathname === "/" ? "disabled-link underline-offset-8 w-10 sm:w-fit" : "underline-offset-8 w-10 sm:w-fit"}>
                                    <a className={location.pathname === "/" ? "disabled-link sm:underline" : "sm:underline"} href="/">General</a>
                                    <a className={location.pathname === "/" ? "disabled-link underline" : "underline"} href="/"> Definition</a>
                                    </div>
                                </div> 
                                
                                <div className={location.pathname === "/about" ? hereStyle : thereStyle}>                
                                    <a href="/about">About</a>
                                </div>
                                <div className={location.pathname === "/contact" ? hereStyle : thereStyle}>                
                                    <a href="/contact">Contact</a>
                                </div>               
                                
                            </div>
                        </div>  
                    </div>
                </div>
            </div>  
        </div>
    )
};

