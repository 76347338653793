import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import SuspenseContent from './containers/SuspenseContent';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>    
    <Suspense fallback={<SuspenseContent/>}>    
        <App />
    </Suspense>        
  </React.StrictMode>
);


reportWebVitals();
