import React, { lazy, useEffect, useState } from 'react';
import { BrowserRouter as Router, useLocation, Route, Routes, Navigate} from 'react-router-dom';
import './App.css';



//import SuspenseContent from './containers/SuspenseContent';


const Main = lazy(() => import('./pages/Main'));
const About = lazy(() => import('./pages/About'));
const Contact = lazy(() => import('./pages/Contact'));
const NotFound = lazy(() => import('./pages/NotFound'));
const MainNavigation = lazy(() => import('./components/MainNavigation'));
const MainFooter = lazy(() => import('./components/MainFooter'));


const RoutableContent = () => {

  const location = useLocation();  

  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");
  
  
  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [location, displayLocation]);


  return (
    <div
      className={`${transitionStage}`}
      onAnimationEnd={() => {
        if (transitionStage === "fadeOut") {
          setTransistionStage("fadeIn");
          setDisplayLocation(location);
        }
      }}
    >
        <Routes location={displayLocation}>
            <Route path="/" index element={<Main />} />            
            <Route path="/about" element={<About />}/>
            <Route path="/contact" element={<Contact />} />            
            <Route path="/404" element={<NotFound />} />                        
            <Route path="*" element={<Navigate to="/404" />} />

          </Routes>       
    </div>
  );
}

function App()
{ 
  
  return (
    <>
      
        <div className='flex px-6 sm:px-32'>
            <div className='flex-1 justify-center'>
                <div className='mx-auto max-w-5xl text-white'>                  
                  <Router>
                    <MainNavigation/>                                                            
                    <RoutableContent />                                                         
                  </Router>
                  <MainFooter />
                </div>
            </div>
        </div>      
    </>
  );
}







export default App;

